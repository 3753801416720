import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import TwoColumn from "../../components/two-column"
import CustomerJourney from '../../components/customer-journey'
import StackedTexts from '../../components/stacked-texts'
import PlanAction from '../../components/plan-action'
import FindOutMoreCTA from '../../components/find-out-more-cta'
import Columnar from '../../components/columnar'

// import {Helmet} from 'react-helmet'

const developersContent = {
  title: "From day one, Billsby was designed to make things easy for developers.",
  description: [
    {
      text: "When you choose Billsby, you'll find you've chosen a platform that makes your life easier whilst enabling the business around you to be more flexible, make faster changes and feel empowered to act without development hours."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up today'
    },
    {
      scheduleButton: true
    }
  ],
  isBanner: true,
  sectionDevelopers: true,
  image: 'home-developers.png',
  imageName: 'developers svg',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right'
}

const featureTags = {
  title: "Feature Tags make access control and permissions a breeze.",
  list: [
    {
      title: 'With other billing platforms, you grant permissions and access based on plans',
      description: <> This sounds fine to start with - but it doesn't scale. As your business grows, you'll amass hundreds of plans and need to accommodate edge cases - like giving certain customers access to extra features, or managing permissions for beta tests or just to quickly QA whether things are working as you'd expect.</>,
    },
    {
      title: 'With Billsby, you grant permissions and access with Feature Tags',
      description: <> Feature Tags can be associated with plans, add-ons and allowances - or individual customers. So, for example, you can check for the presence of #spa on a customers account to determine whether they should be let into the spa, or #whitelabel to determine if they should be able to use your white label feature.</>,
    },
  ],
  image: require('../../images/billsby-feature-tags.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true,
  btnText: 'Feature Tags',
  btnClass: 'adoric_video_featuretags'
}

const trackUsage = {
  title: "Track usage in real-time, without any effort on your end.",
  list: [
    {
      title: 'With other billing platforms, you keep track of usage ',
      description: <>So if you're billing for something like minutes or units each month, you need to spin up a system to count these minutes through the month, then once the month is over, the customers invoice is suspended until you report the usage back, and then billing happens.</>,
    },
    {
      title: 'With Billsby, you can send usage information in real time',
      description: <>Send usage to us as soon as it's consumed, and we'll keep track in real time. You can request usage balances whenever you'd like and use this to build advanced system behaviours - and because we know about overage ahead of time, you can see and manage it in the control panel.</>,
    },
  ],
  align: 'right',
  image: require('../../images/billsby-allowances.svg'),
  imageName: 'billsby-track-usage',
  svg: true,
  btnText: 'Metered Allowances',
  btnClass: 'adoric_video_allowances'
}

const orderHierarchy = {
  title: "Products, plans and cycles give you the order and hierarchy you need",
  list: [
    {
      title: 'With other billing platforms, everything is a plan',
      description: <>This lack of structure means you have to understand at your end that some plans are different and others are just variants of the same thing - like a monthly and annual cycle length. This makes it very difficult to create dynamic pricing tables and pages.</>,
    },
    {
      title: 'With Billsby, everything is logically structured',
      description: <>By structuring your plan options as products, plans and cycles, and associating feature tags with those options, we make it possible for you to dynamically generate all of your pricing tables and marketing pages - which means plans can change at any time with no development effort or changes required.</>,
    },
  ],
  image: require('../../images/robot-board.svg'),
  imageName: 'billsby-order-hierarchy',
  svg: true,
  btnText: 'Products, Plans and Cycles',
  btnClass: 'adoric_video_products'
}

const doItInBillsby = {
  title: "If you can do it in Billsby, you can do it in the API.",
  description: [
    {
      text: "We built Billsby as an API first platform - so everything that you can do in the control panel, you can do with our API. Want to create a completely headless billing system and design your own front-end and back-end interfaces from scratch? Sure. You can do that. Or use our pre-built components and replace them overtime with your own custom systems - so it's quick to get started, but you don't sacrifice flexibility long term."
    }
  ],
  imagePosition: 'full-right',
  textColor: '#fff',
  backgroundColor: '#243f5c',
  backgroundImage: require('../../images/doggie@2x.png'),
  backgroundPosition: 'right',
  sectionProtection: true,
  marginTop: '20px'
}

const planActionContent = {
  title: "Power your subscriptions with Zapier.",
  description: "Once customers sign-up and as their subscription changes, use Zapier to complete follow up tasks across all of the other tools and services you use to run your business.",
  svg: true,
  image: require('../../images/plan-action.svg'),
  imageName: 'plan action image',
  cards: [
    {
      image: 'plan-action-1.png',
      text: 'Add the new customer to your CRM system',
      svgLogo: true,
      logo: require('../../images/pipedrive.svg'),
      logoName: 'pipedrive'
    },
    {
      image: 'plan-action-2.png',
      text: 'Subscribe them to the correct mailing list',
      logo: 'mailchimp.png',
      logoName: 'mailchimp'
    },
    {
      image: 'plan-action-3.png',
      text: 'Post out their welcome pack',
      logo: 'shipwire.png',
      logoName: 'shipwire'
    }
  ],
  url: '/product/integrations/zapier',
  buttonColor: 'blue',
  buttonText: 'Learn about Zapier',
  comingSoonText:''
}

const behindPaywalls = {
  image: require('../../images/support-talk.svg'),
  imageName: 'billsby-payment',
  svg: true,
  stackedTexts : [
    {
      title: "We don't hide the cool stuff behind paywalls.",
      list: [
        {
          title: '',
          description: <>All too often, companies tell you about how easy it's going to be to develop and integrate their solution, and then hide the features that'll make it easy behind a paywall and ask you for more money to use it.</>,
        },
        {
          title: '',
          description: <> Not here. Everything we've mentioned on this page is included with the Billsby Complete plan - which costs just 0.4% of revenue with no monthly fees. So all you need to do is convince the business to let you decide how to spend the money it'll save - perhaps an office beer keg?</>,
        },
      ]
    },
    {
      title: "We're here when you need us.",
      list: [
        {
          title: '',
          description: <>Our developer community, email support, chat support and phone call-backs are included with every plan, so if you need any help or support we're never too far away. You can review our documentation without signing up, too.</>,
        },
      ],
      buttons: [
        {
          btnColor: 'blue',
          btnText: 'Documentation',
          urlType: 'external',
          url: 'https://support.billsby.com/docs'
        },
        {
          btnColor: 'blue',
          btnText: 'API specification',
          urlType: 'external',
          url: 'https://support.billsby.com/reference'
        }
      ]
    }
  ]
}

const findOutMoreCTAContent = {
  title: "Explore away, there's no credit card required",
  description: "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  backgroundColor: '#F3F5FB',
  buttons : [
    {
      url: 'https://app.billsby.com/registration',
      buttonText: 'Sign up today',
      buttonColor: 'orange',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const columnarContent = [
  {
    title: "Reduce your PCI-DSS compliance burdens and headaches.",
    list: [
      {
        title: '',
        description: <>Billsby is PCI-DSS compliant and whether you use our JavaScript checkout or our card tokenizer, you'll never store payment card numbers or details anywhere on your server or infrastructure, or have to transfer them to us. This significantly reduces your compliance challenges.</>,
      },
    ]
  },
  {
    title: "Spend less time integrating systems and services.",
    list: [
      {
        title: '',
        description: <>You'll never have to worry about payment gateway integrations again - and your business will even have the flexibility to switch gateways at any time with no impact on you or your development cycle - it only takes a click to immediately start billing through an alternate provider.</>,
      },
    ]
  }
]
  



const Developers = () => {
  return (
    <Layout className="developers">
      <SEO 
        title="Billsby for Developers | Billsby | Powerful, customizable subscription billing software" 
        description="Headless billing APIs, real-time usage reporting and feature tags make Billsby the most developer friendly subscription billing and recurring payments management software." 
        url="https://www.billsby.com/product/developers"
      />

      <TwoColumn content={developersContent}/>
      <CustomerJourney content={featureTags} />
      <CustomerJourney content={trackUsage} />
      <CustomerJourney content={orderHierarchy} />
      <TwoColumn content={doItInBillsby}/>
      <Columnar content={columnarContent} />
      <PlanAction content={planActionContent} />
      <StackedTexts content={behindPaywalls} />
      <FindOutMoreCTA content={findOutMoreCTAContent} />
    </Layout>
  )
}

export default Developers